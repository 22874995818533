
import {Component, Vue} from 'vue-property-decorator';
import Http from '@/Http';
import AppStore from '@/components/AppStore';
import DiagnosticCardStatusChip from '@/components/dcs/DiagnosticCardStatusChip.vue';
import ExportExcel from '@/components/ExportExcel.vue';
import DcsExportExcel from '@/components/dcs/DcsExportExcel.vue';


@Component({
  components: {DcsExportExcel, ExportExcel, DiagnosticCardStatusChip}
})
export default class DcsTable extends Vue {

  loading = true;
  items: any[] = [];

  backlight = '';
  regNumbers: string[] = AppStore.regNumbers;

  filterData = {
    regNumber: '',
    status: '',
    query: '',
  };


  load() {
    AppStore.showLoader();
    this.loading = true;
    Http.post('/dcs', this.filterData).then((response: any) => {
      this.items = response;
    }).finally(() => {
      this.loading = false;
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

  init() {
    this.load();
  }

  mounted() {

    if (document.location.hash) {
      const rn = document.location.hash.substr(1);
      this.filterData.regNumber = decodeURI(rn);
    }
    this.init();

  }
}
