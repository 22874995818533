
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AppHelper from '@/components/AppHelper';

@Component({})
export default class DiagnosticCardStatusChip extends Vue {

  @Prop()
  expiryDate!: string | null;

  @Prop({default: 'normal'})
  view!: 'normal' | 'short';

  label = '';
  color = '';
  icon = '';

  @Watch('expiryDate')
  expiryDateWatch() {
    this.inti();
  }

  getNoun(days: number) {
    return AppHelper.getNoun(days, 'день', 'дня', 'дней');
  }

  mounted() {
    this.inti();
  }

  inti() {
    const d = this.expiryDate;
    const current = this.$moment().startOf('day');

    if (d === null) {
      this.icon = 'mdi-close-box-outline';
      this.color = 'grey';
      this.label = 'Не найдена';
      return;
    }

    const days = this.$moment(d).diff(current, 'days');

    if (days < 0) {
      this.icon = 'mdi-clock-alert-outline';
      this.color = 'red';
      this.label = 'Просрочена';
      return;
    }

    if (days === 0) {

      const hours = this.$moment(d).diff(current, 'hours');

      this.icon = 'mdi-cancel';
      this.color = 'red darken-4';
      if (hours < 0) {
        this.label = 'Заканчиватеся сегодня';
      } else {
        this.label = 'Заканчиватеся завтра';
      }
      return;
    }

    if (days <= 21) {
      this.icon = 'mdi-cancel';
      this.color = 'red darken-4';
      this.label = 'Заканчивается через ' + days + ' ' + this.getNoun(days);
      return;
    }

    this.icon = 'mdi-checkbox-marked-outline';
    this.color = 'green';
    this.label = 'Активная ещё ' + days + ' ' + this.getNoun(days);


  }

}
