
import {Component, Vue, Watch} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';

@Component({})
export default class ModeChangeButton extends Vue {
  mode = AppStore.displayMode;

  loading = false;

  @Watch('mode')
  modeWatch() {
    AppStore.setDisplayMode(this.mode);
  }

  mounted() {
    AppStore.onDisplayMode(v => this.mode = v);
  }

}
