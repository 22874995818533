
import {Component, Vue} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import DcsTable from '@/components/dcs/DcsTable.vue';
import MenuTop from '@/components/MenuTop.vue';

@Component({
  components: {MenuTop, DcsTable, AppTopBar}
})
export default class DcsView extends Vue {

  containerWidth = AppStore.containerWidth;

  mounted() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
    AppStore.setDisplayModeTable();
  }
}
