
import {Component, Vue} from 'vue-property-decorator';
import ExportExcelDialog from '@/components/ExportExcelDialog.vue';

@Component({
  components: {ExportExcelDialog}
})
export default class DcsExportExcel extends Vue {

  view = false;

}
