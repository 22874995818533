
import {Component, Prop, Vue} from 'vue-property-decorator';
import Http from '@/Http';
import ModeChangeButton from '@/components/ModeChangeButton.vue';

@Component({
  components: {ModeChangeButton}
})
export default class ExportExcel extends Vue {

  @Prop({default: 'v1'})
  version!: 'v1' | 'v2';

  view = false;
  loading = true;
  url = '';

  exportExcel() {

    this.view = true;
    this.loading = true;
    let u = '/transports/export';
    if (this.version === 'v2') {
      u = '/v2/transports/export';
    }
    Http.post(u).then((value: any) => {
      this.url = value.url;
    }).finally(() => {
      this.loading = false;
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

}
